import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css';

export default class StaticHomeQC extends Component {
  render() {
    return (
      <div className="li-static-home mt-2">
        <div className="container">
          <div className="row">
            
          </div>
        </div>
      </div>
    )
  }
}
