import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './style.css';

export default class BannerMiddle extends Component {
  render() {
    return (
      <div className="li-static-banner desktop" style={{marginTop: 0}}>
        <div className="team-area pt-60 pt-sm-44">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="li-section-title capitalize mb-25">
            <h2><span>SẢN PHẨM ĐẠI DIỆN CỦA BYZ</span></h2>
          </div>
        </div>
      </div> {/* section title end */}
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="team-member mb-60 mb-sm-30 mb-xs-30">
            <div className="team-thumb">
            <a href="/categories/56">
              <img src="/images/team/270-hoco.jpg" alt="Our Team Member" />
             </a> 
            </div>
            <div className="team-content text-center">
              <h3>CÁP SẠC.</h3>
              <p>Sản phẩm BYZ</p>             
            </div>
          </div>
        </div> {/* end single team member */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="team-member mb-60 mb-sm-30 mb-xs-30">
            <div className="team-thumb">
            <a href="/categories/57">
              <img src="/images/team/270-byz.jpg" alt="Our Team Member" />
            </a>
            </div>
            <div className="team-content text-center">
              <h3>CỦ SẠC.</h3>
              <p>Sản phẩm BYZ</p>
              <a href="/"></a>
            </div>
          </div>
        </div> {/* end single team member */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="team-member mb-30 mb-sm-60">
            <div className="team-thumb">
            <a href="/categories/58">
              <img src="/images/team/270-remax.jpg" alt="Our Team Member" />
            </a>
            </div>
            <div className="team-content text-center">
              <h3>TAI NGHE.</h3>
              <p>Sản phẩm BYZ</p>
             
            </div>
          </div>
        </div> {/* end single team member */}
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="team-member mb-30 mb-sm-60 mb-xs-60">
            <div className="team-thumb">
            <a href="/categories/59">
              <img src="/images/team/270-kingkong.jpg" alt="Our Team Member" />
             </a> 
            </div>
            <div className="team-content text-center">
              <h3>SẠC DỰ PHÒNG.</h3>
              <p>Sản phẩm BYZ</p>
            </div>
          </div>
        </div> {/* end single team member */}
      </div>
    </div>
  </div>
      </div>

    )
  }
}
