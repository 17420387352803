import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { startLoading, doneLoading } from '../../utils/loading'
import { actSearchProductsRequest } from '../../redux/actions/products';
import { actTokenRequest } from '../../redux/actions/auth'
import { toast } from 'react-toastify';
import Logo from '../../assets/images/logo.png';
import ViewMoreMobile from './ViewMoreMobile';
import { FaShippingFast } from 'react-icons/fa';
import LogoHome from 'assets/images/LogoHome.png';

class HeaderMiddle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      textSearch: ''
    }
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleClick = () => {
    const { textSearch } = this.state;
    if (textSearch === '' || textSearch === null) {
      return toast.error('Nhập tên sản phẩm...');
    }
    startLoading();
    this.props.searchProduct(textSearch)
    doneLoading();
     this.props.history.push(`/products/search/${textSearch}`)
  }

  loadingPage = () => {
    startLoading();
    doneLoading();
  }

  logOut = async () => {
    localStorage.removeItem('_auth');
    const token = null;
    startLoading();
    await this.props.setTokenRedux(token);
    // await this.props.reset_productFavorites();
    doneLoading();
  }

  render() {
    const { textSearch, redirectToSearch } = this.state;
    const { countCart, user } = this.props;
     if(redirectToSearch) {
       return <Redirect  to={`/products/search/${textSearch}`}/>
     }
    return (
      <div className="header-middle pl-sm-0 pr-sm-0 pl-xs-0 pr-xs-0">
        <div className="container header-container">
          <div className="row">
            {/* Begin Header Logo Area */}
            <div className="col-lg-3 desktop">
              <div className="logo pb-sm-30 pb-xs-30">
                <Link to="/">
                  <img src={Logo} alt="" />
                </Link>
              </div>
            </div>
            {/* Header Logo Area End Here */}
            {/* Begin Header Middle Right Area */}
            <div className="col-lg-9 pl-0 ml-sm-15 ml-xs-15 search-box-mobile">
              {/* <Link className="mobile-flex icon-home" ><img src="/static/media/LogoHome.c38d0c4e.jpg" alt="not found" style={{height: 30, width: 30}}/></Link> */}
            <Link className="mobile-flex icon-home" to="/"><img src={LogoHome} alt="not found" style={{height: 30, width: 30}}/></Link>
              {/* Begin Header Middle Searchbox Area */}
              <form className="hm-searchbox">
                <input name="textSearch" value={textSearch} type="text" onChange={this.handleChange} placeholder="Phụ Kiện BYZ Giá Sỉ..." />
                {/* <button className="li-btn" type="submit"></button> */}
                <Link onClick={this.handleClick} to={`/products/search/${textSearch}`}>
                  <button className="li-btn" type="submit"><i className="fa fa-search" /></button>
                </Link>
              </form>
              {/* Header Middle Searchbox Area End Here */}
              {/* Begin Header Middle Right Area */}
              <div className="header-middle-right desktop">
                <ul className="hm-menu">
                  {/* Begin Header Middle Wishlist Area */}
                  <li className="hm-wishlist">
                    <Link to='/history-booking' className="order-icon">
                      <FaShippingFast style={{fontSize:"28px"}}/>
                    </Link>
                  </li>
                  {/* Header Middle Wishlist Area End Here */}
                  {/* Begin Header Mini Cart Area */}
                  <li className="hm-minicart">
                    <Link to="/cart">
                      <div className="hm-minicart-trigger">
                        <span className="item-icon" />
                        <span className="item-text">
                          <span className="cart-item-count">{countCart.length > 0 ? countCart.length : 0}</span>
                        </span>
                      </div>
                    </Link>
                    <span />
                  </li>
                </ul>
              </div>
              {/* Setting user- login, logout cho mobile */}
              <ViewMoreMobile user={user} logout={this.logOut}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countCart: state.cart,
    countFavorite: state.favorites,
    user: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    searchProduct: (q) => {
      dispatch(actSearchProductsRequest(q))
    },
    setTokenRedux: (token) => {
      dispatch(actTokenRequest(token))
    }
  }
}

export default withRouter (connect(mapStateToProps, mapDispatchToProps)(HeaderMiddle));
