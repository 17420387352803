import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import './style.css';
import { actTokenRequest } from '../../redux/actions/auth';
import {startLoading, doneLoading} from '../../utils/loading';
import {
  AiFillPhone
} from "react-icons/ai";

class HeaderTop extends Component {

  logOut = async () => {
    localStorage.removeItem('_auth');
    const token = null;
    startLoading();
    await this.props.setTokenRedux(token);
    doneLoading();
  }

  loadingPage = () => {
    startLoading();
    doneLoading();
  }

  render() {
    const { user } = this.props;
    return (
      <div className="header-top desktop">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="header-top-left">
                <ul className="phone-wrap">
                  <li>Đại Lý Phân Phối Phụ Kiện Hãng BYZ </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="header-top-right">
                <ul className="ht-menu">
                  <div className="phone-header-top"><AiFillPhone style={{fontSize:"20px", marginRight:"10px"}} />
                  <a href="tel:0931366680">0931 366 680 &ensp; </a>
                    
                  <a href="tel:0988991692"> 0988 991 692 &ensp; </a>
                    |
                   </div>
                  <span className="btn-Logout" onClick={this.logOut}>Đăng xuất</span>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTokenRedux: (token) => {
      dispatch(actTokenRequest(token))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop)
