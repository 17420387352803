import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ZaloImg from 'assets/images/ZaloImg.png';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import EventNoteIcon from '@material-ui/icons/EventNote';
import './style.css'

export default class Social extends Component {
  render() {
    return (
      <div className="fix-div-social">
        <ul className="fix-social social-link">
        
        <li className="youtube">
          <a href="tel:0988991692" data-toggle="tooltip" title="Điện thoại:0988 991 692">
            <LocalPhoneIcon style={{color:"#fff"}}/>
          </a>
        </li>

        <li className="youtube">
          <a href="tel:0931366680" data-toggle="tooltip" title="Điện thoại:0931 366 680">
            <LocalPhoneIcon style={{color:"#fff"}}/>
          </a>
        </li>
        
        <li className="facebook">
          <a href="https://facebook.com/" data-toggle="tooltip" title="FanPage">
            <i className="fa fa-facebook" />
          </a>
        </li>
        <li className="google-plus">
          <Link to="/contact" data-toggle="tooltip" title="Liên hệ">
            <EventNoteIcon style={{color:"#fff"}}/>
          </Link>
        </li>
      </ul>
      </div>
      
    )
  }
}
