import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import './style.css';
import { startLoading, doneLoading } from 'utils/loading';
import ReCAPTCHA from "react-google-recaptcha";
import { actLoginRequest } from '../../redux/actions/auth';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from 'react-toastify';
import ZaloOA from 'assets/images/ZaloOA.png';
import { API_URL } from 'constants/Config';
const MySwal = withReactContent(Swal);

class FooterMiddle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      redirect: false,
      isVerified: false,
      open: false
    }
  }

  body = () => {
    return (
      <div>body</div>
    )
  }

  handleSubmit = async () => {
    const {email} = this.state;
    if(!email) {
      toast.error('Hãy nhập email hoặc số điệnt thoại');
      return;
    }
    this.setState({
      open: true
    })
  }

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }
  
  onChangeCapcha = (value) => {
    if(value) {
      this.setState({
        isVerified: true
      })
    }
  }

  handleSubmitCapCha = async (e) => {
    const { email, isVerified } = this.state;
    // verifi captcha
    if(!isVerified) {
      return toast.error('Bạn chưa xác nhận mã capcha!');
    }
    const user = { email }
    startLoading();
    await this.props.loginRequest(user);
    doneLoading();
    this.setState({
      open: false
    })
  }

  render() {
    const {email, open} = this.state;
    const {auth} = this.props;
    
    return (
      <div className="footer-static-middle">
        <div className="container">
          <div className="footer-logo-wrap pt-50 pb-35">
            <div className="row">
              {/* Begin Footer Logo Area */}
              <div className="col-lg-4 col-md-6">
                <ul className="des footer-block">
                  <h3 className="footer-block-title">Liên hệ</h3>
                  <li>
                    <span>🏢 Văn Phòng Giao Dịch: </span>
                    Ngõ 68, Phố Triều Khúc, Quận Thanh Xuân Hà Nội.<br /> 
                    Ủy Quyền Phân Phối - Bảo Hành Sản Phẩm BYZ
                  </li>
                  <li>
                    <span>☎: </span>
                    <a href="tel:0931366680">0931 366 680 </a> 
                  </li>
                  <li>
                    <span>☎: </span>
                    <a href="tel:0988991692"> 0988 991 692 </a>
                  </li>
                  <li>
                    <span>📧: </span>
                    <a href="mailto:byzvietnam.vn@gmail.com">Gửi thư hỗ trợ</a>
                  </li>
                </ul>
              </div>
              {/* Footer Logo Area End Here */}
              {/* Begin Footer Block Area */}
              <div className="col-lg-2 col-md-3 col-sm-6">
                <div className="footer-block">
                  <h3 className="footer-block-title">Liên kết</h3>
                  <ul>
                    <li><a href="/">Trang chủ</a></li>
                    <li><a href="/products">Sản phẩm</a></li>
                    <li><a href="/about">Giới thiệu</a></li>
                    <li><a href="/contact">Liên hệ</a></li>
                  </ul>
                </div>
              </div>
              {/* Footer Block Area End Here */}
              {/* Begin Footer Block Area */}
              <div className="col-lg-2 col-md-3 col-sm-6">
                <div className="footer-block">
                  <h3 className="footer-block-title">Dịch vụ</h3>
                  <ul>
                    <li><a href="/">Giao hàng</a></li>
                    <li><a href="/">Yêu thích</a></li>
                    <li><a href="/">Đặt hàng</a></li>
                  </ul>
                </div>
              </div>
              {/* Footer Block Area End Here */}
              {/* Begin Footer Block Area */}
              <div className="col-lg-4">
                <div className="footer-block">
                  <h3 className="footer-block-title">Theo dõi chúng tôi</h3>
                  <ul className="social-link">
                    <li className="rss">
                      <a href="https://rss.com/" data-toggle="tooltip" title="RSS">
                        <i className="fa fa-rss" />
                      </a>
                    </li>
                    <li className="google-plus">
                      <a href="https://g.page/r/CbhARUWoWwtuEAE" data-toggle="tooltip" title="Google Plus">
                        <i className="fa fa-google-plus" />
                      </a>
                    </li>
                    <li className="facebook">
                      <a href="https://www.facebook.com/" data-toggle="tooltip" title="Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="youtube">
                      <a href="https://www.youtube.com/" data-toggle="tooltip" title="Youtube">
                        <i className="fa fa-youtube" />
                      </a>
                    </li>
                    <li className="instagram">
                      <a href="https://www.instagram.com/" data-toggle="tooltip" title="Instagram">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Begin Footer Newsletter Area */}
                <div className="footer-newsletter">
                  <h4>Tình trạng đơn hàng của bạn</h4>
                  {/* <form onSubmit={(event) => this.handleSubmit(event)}> */}
                    <div id="mc_embed_signup_scroll">
                      <div id="mc-form" className="mc-form subscribe-form form-group">
                        <input 
                          id="mc-email" 
                          type="text" 
                          name="email" 
                          placeholder="Điền số điện thoại hoặc email" 
                          value={email}
                          onChange = {this.handleChange}
                        />
                        <Link 
                          onClick={this.handleSubmit} 
                          to='/login'
                          className="quick-view-btn" 
                          data-toggle="modal" 
                          data-target="#modal-login"
                          
                        >
                          <button className="btn">Kiểm tra</button>
                        </Link>
                      </div>
                    </div>
                  {/* </form> */}
                </div>
                {/* Footer Newsletter Area End Here */}
              </div>
              {/* Footer Block Area End Here */}
            </div>
            <Drawer 
              anchor={'bottom'} 
              open={open} 
              onClose={this.handleClose}
            >
              <div className="container capcha-footer"> 
                <h3>Xác nhận capcha để kiểm tra đơn hàng</h3>
                <ReCAPTCHA
                  ref={e => (this.captcha = e)}
                  sitekey="6LcRg8gdAAAAABATNGd_JmIyH8DN78iPxngHqXYp"
                  onChange={this.onChangeCapcha}
                />
                <Link 
                  onClick={this.handleSubmitCapCha}
                  to='/login'
                  style={{zIndex:"10"}}
                >
                  <button className="btn-capcha">Kiểm tra</button>
                </Link>
                {/* <button className="btn-capcha" onClick={this.handleSubmitCapCha}>Kiểm tra</button> */}
              </div>
             
            </Drawer>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (user) => {
      dispatch(actLoginRequest(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterMiddle)
