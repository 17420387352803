import React, { Component } from "react";
import callApi from "../../utils/apiCaller";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./style.css";
const MySwal = withReactContent(Swal);

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      subject: "",
      message: "",
      isVetify: false
    };
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };
  
  handleChangePhone = async (e) => {
    let phone = e.target.value;
    if(e.target.value.length > 10) {
      return toast.error('Số điện thoại không vượt quá 10 số');
    }
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        phone: phone
      })
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { name, phone, subject, message, isVetify } = this.state;
    const newSubject = subject ? subject : null;
    const newMessage = message ? message : null;
    if(!isVetify) {
      return toast.error('Bạn chưa xác nhận capcha!')
    }
    if(!name || !phone) {
      return toast.error('Hãy nhập đầy đủ thông tin!')
    }
    const newContact = {
      name,
      phone,
      subject: newSubject,
      message: newMessage
    };
    const res = await callApi("contacts", "POST", newContact, null);
    if (res && res.status === 200) {
      MySwal.fire({
        title: "Đăng ký thành công?",
        text: "Bạn đã đăng ký nhận báo giá thành công, chúng tôi sẽ liên hệ với bạn sớm nhất?",
        icon: "success",
        showConfirmButton: true,
        timer: 15000,
      })
      this.captcha.reset();
      this.setState({
        name: "",
        phone: "",
        subject: "",
        message: ""
      });
    }
  };

  onChangeCapcha = (value) => {
    if(value) {
      this.setState({
        isVetify: true
      })
    }
  }

  render() {
    const { name, phone, subject, message } = this.state;
    return (
      <div className="contact-main-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 offset-lg-1 col-md-12 order-1 order-lg-2">
              <div className="contact-page-side-content">
              <h4 className="contact-page-title">BYZ Việt Nam</h4>
                <p className="contact-page-message mb-25">
                  Văn Phòng Giao Dịch.<br />
                  Phụ Kiện Điện Thoại BYZ. <br /> 
                  Ủy Quyền Phân Phối - Bảo Hành SP BYZ.
                </p>
                <div className="single-contact-block">
                  <h4>
                    <a href="https://g.page/r/CbhARUWoWwtuEAE">
                    <i className="fa fa-fax" /> Địa chỉ </a>
                  </h4>
                  <p>68-Triều Khúc - Thanh Xuân - Hà Nội</p>
                </div>
                <div className="single-contact-block">
                  <h4>
                    <i className="fa fa-phone" /> Số điện thoại
                  </h4>
                  <p>Mobile: <a href="tel:0988991692"> 0988 991 692 </a> hoặc <a href="tel:0931366680">  0931 366 680 </a> </p>
                  <p>Hotline: <a href="tel:0988991692"> 0988 991 692 </a> </p>
                </div>
                <div className="single-contact-block last-child">
                  <h4>
                    <i className="fa fa-envelope-o" /> Email:
                  </h4>
                  <p><a href="mailto:byzvietnam.vn@gmail.com">byzvietnam.vn@gmail.com </a></p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 order-2 order-lg-1">
              <div className="contact-form-content">
                <h3 className="contact-page-title">Đăng ký nhận báo giá</h3>
                <div className="contact-form">
                  <form onSubmit={event => this.handleSubmit(event)}>
                    <div className="form-group">
                      <label>
                        Tên của bạn<span className="required">*</span>
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="text"
                        name="name"
                        value={name}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Số điện thoại <span className="required">*</span>
                      </label>
                      <input
                        onChange={this.handleChangePhone}
                        type="text"
                        name="phone"
                        value={phone}
                      />
                    </div>
                    <div className="form-group">
                      <label>Nội dung</label>
                      <input
                        onChange={this.handleChange}
                        type="text"
                        name="subject"
                        value={subject}
                      />
                    </div>
                    <div className="form-group mb-30">
                      <label>Chi tiết</label>
                      <textarea
                        onChange={this.handleChange}
                        name="message"
                        value={message}
                      />
                    </div>
                    <div className="form-group btn-contact-submit">
                      <button
                        type="submit"
                        value="submit"
                        className="li-btn-3"
                        name="submit"
                        style={{marginBottom:"20px"}}
                      >
                        Gửi
                      </button>
                    </div>
                    <ReCAPTCHA
                      ref={e => (this.captcha = e)}
                      sitekey="6LcRg8gdAAAAABATNGd_JmIyH8DN78iPxngHqXYp"
                      onChange={this.onChangeCapcha}
                    />
                  </form>
                </div>
                <p className="form-messege" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
